.snazzy-description{
    transition: all 3s ease-in-out;
    opacity:0;
    max-height: 0;
    overflow: hidden;
}
.snazzy-description.open{
    opacity: 1;
    max-height: 200px;
}
.snazzy-collapse{
    background-color: transparent;
}
.snazzy-collapse .ant-collapse-header{
    display: none!important;
}
.snazzy-collapse .ant-collapse-content{
    border-radius: 8px!important;
    background-color: white;

}
.snazzy-collapse .ant-collapse-content-box{
    padding: 0!important;
}
.snazzy-title{
    margin-top: 8px;
    margin-bottom: 6px;
    transition: margin-bottom .2s ease-in-out, margin-top .25s;
}

.snazzy-title.open{
    margin: 0;
}