

.body{
     min-height: 100vh;
    background: #f1f1f1;
   /*background: linear-gradient(to bottom, #93a4f5, #e2e2e2); !* Define your gradient *!*/
   /* background-attachment: fixed; !* Ensure the background stays fixed *!*/
}
.body.dark{
    background-color: #1b1c1c;
    /*background: linear-gradient(to bottom, #101a44, #212121); !* Define your gradient *!*/
}
.screen-image{
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}