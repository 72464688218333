.animation{
    transform: scale(0.99) translateY(15px);
    opacity: 0;
    animation: fadeIn 0.4s forwards;
    -webkit-animation: fadeIn 0.4s forwards;
    -moz-animation: fadeIn 0.4s forwards;
    -o-animation: fadeIn 0.4s forwards;
    -ms-animation: fadeIn 0.4s forwards;
}
.animation.a-1{
    animation-delay: 0.3s;
}
.animation.a-2{
    animation-delay: 0.4s;
}
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: scale(0.99) translateY(15px);
    }
    100% {
        opacity: 1;
        transform: scale(1) translateY(0);
    }
}
.ant-btn-background-ghost{
    color: white!important;
}