/*
Must set with js:
left,
top,
height,
width,
padding,
*/
.motion-circle{
    transition: all 0.19s;
    position: fixed;
    background-color: #f1f1f1;
    border-color: #f1f1f1;
    border-radius: 50%;
    opacity: 0.3;
    border-width:0;
    width:0;
    height:0;
    padding:0;
    overflow: hidden;
}
.dark .motion-circle{
    background-color: #1b1c1c;
    border-color: #1b1c1c;
}
.transition .motion-circle{
    opacity: 1;
}

.motion-overlay{
    transition: all 0.19s;
    position: relative;
    background-color: #f1f1f1;
    opacity: 0.9;
    width: 100vw;
    height: 100vh;
    padding-bottom: 3rem;
}
.motion-overlay.fixed{
    position: fixed!important;
    top:0!important;
    left:0!important;
    transition-duration: 0s!important;
}
.dark .motion-overlay{
    background-color: #1b1c1c;
}
.transition .motion-overlay{
    opacity: 1;
}


